<template>
  <div class="bank-balance-details">
    <v-card class="mb-2">
      <v-card-title>Detail Saldo</v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <date-range-picker v-model="dateRange"></date-range-picker>
      </v-card-text>
    </v-card>

    <v-card class="mb-2">
      <v-card-title class="pb-1"> Total </v-card-title>

      <v-card-text>
        <h3 v-text="transactions.total"></h3>
      </v-card-text>
    </v-card>

    <v-card>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        filled
        hide-details
        single-line
        @input="getData"
      ></v-text-field>

      <v-data-table
        :headers="transactions.headers"
        :items="transactions.data"
        :loading="transactions.loading"
        :options.sync="options"
        :server-items-length="transactions.count"
        class="elevation-1"
      >
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'BankBalanceDetails',

  data() {
    return {
      bankAccountID: 0,

      filter: {
        dateRange: this.getYearDateRange(),
      },

      options: {},

      search: '',

      transactions: {
        count: 0,

        data: [],

        headers: [
          { text: 'Tipe', value: 'type' },
          { text: 'No', value: 'trans_no' },
          { text: 'Referensi', value: 'ref' },
          { text: 'Person', value: 'person' },
          { text: 'Tanggal', value: 'trans_date' },
          { text: 'Nilai', value: 'amount' },
        ],

        loading: false,

        total: 0,
      },
    }
  },

  watch: {
    options: {
      deep: true,

      handler() {
        this.getData()
      },
    },
  },

  mounted() {
    this.bankAccountID = this.$route.params.id

    this.getData()
  },

  methods: {
    getData() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      this.transactions.data = []
      this.transactions.loading = true

      this.$http
        .get('/bank/' + this.bankAccountID + '/balance/details', {
          params: {
            search: this.search,
            sortBy: sortBy[0] ? sortBy[0] : '',
            sortDesc: sortDesc[0] ? sortDesc[0] : '',
            page: page,
            itemsPerPage: itemsPerPage,
            startDate: this.date2sql(this.filter.dateRange.start),
            endDate: this.date2sql(this.filter.dateRange.end),
          },
        })
        .then((result) => {
          this.transactions.count = result.data.count
          this.transactions.data = result.data.result
          this.transactions.total = result.data.total
          this.transactions.loading = false
        })
    },
  },
}
</script>
